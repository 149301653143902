<template>
  <div class="card card-primary">
    <div class="card-header">
      <div class="card-title">
        Kalenderplanung
      </div>
    </div>
    <div class="card-body">
      <div class="card card-primary">
        <div class="card-header">
          <div class="card-title">
            Ferien
          </div>
          <div class="card-tools">
            <button class="btn btn-secondary btn-xs" v-if="$auth.check('holidays.period.create')" @click="openModal('createHolidaysModal')">
              <i class="fas fa-plus"></i> Hinzufügen
            </button>
          </div>
        </div>
        <div class="card-body">
          <table class="table table-hover">
            <thead>
              <tr>
                <th>Von</th>
                <th>Bis</th>
                <th>Beschreibung</th>
                <th>Aktionen</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="holiday in holidays" :key="holiday.id">
                <td>{{holiday.start_date |formatDate}}</td>
                <td>{{holiday.end_date|formatDate}}</td>
                <td>{{holiday.type}}</td>
                <td>
                  <button class="btn btn-warning btn-xs mr-1" v-if="$auth.check('holidays.period.edit')" v-b-popover.hover.top="'Bearbeiten'" @click="openModal('editHolidaysModal', holiday)">
                    <i class="fas fa-edit"></i>
                  </button>
                  <button class="btn btn-danger btn-xs mr-1" v-if="$auth.check('holidays.period.destroy')" v-b-popover.hover.top="'Löschen'" @click="deleteHolidays(holiday.id)">
                    <i class="fas fa-trash"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <CreateHolidaysModal></CreateHolidaysModal>
        <EditHolidaysModal></EditHolidaysModal>
        <CreateOpenSundaysModal></CreateOpenSundaysModal>
        <EditOpenSundaysModal></EditOpenSundaysModal>
      </div>
      <div class="card card-primary">
        <div class="card-header">
          <div class="card-title">
            Differenzierte Öffnungszeiten
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-3" v-for="store in stores" :key="store.id">
              <div class="card card-secondary card-outline">
                <div class="card-header">
                  <div class="card-title">
                    {{store.name}}
                  </div>
                  <div class="card-tools">
                    <button class="btn btn-secondary btn-xs" v-if="$auth.check('holidays.period.create')" v-b-tooltip.hover="'Hinzufügen'" @click="openModal('createOpenSundaysModal', store)">
                      <i class="fas fa-plus"></i>
                    </button>
                  </div>
                </div>
                <div class="card-body">
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th>Datum</th>
                        <th>Von - Bis</th>
                        <th>Aktionen</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="open_sunday in store.open_sundays" :key="open_sunday.id">
                        <td>{{open_sunday.date | formatDate}}</td>
                        <td>
                          {{open_sunday.start_time | toTime}} - {{open_sunday.stop_time | toTime}}
                        </td>
                        <td>
                          <button class="btn btn-warning btn-xs mr-1" v-if="$auth.check('holidays.period.edit')" v-b-popover.hover.top="'Bearbeiten'" @click="openModal('editOpenSundaysModal', open_sunday)">
                            <i class="fas fa-edit"></i>
                          </button>
                          <button class="btn btn-danger btn-xs" v-if="$auth.check('holidays.period.destroy')" v-b-popover.hover.top="'Löschen'" @click="deleteOpenSunday(open_sunday.id)">
                            <i class="fas fa-trash"></i>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="card-footer">
                  <div class="row">
                    <div class="col-md-6 text-left">
                      {{store.name}}
                    </div>
                    <div class="col-md-6 text-right">
                      <button class="btn btn-secondary btn-xs" v-if="$auth.check('holidays.period.create')" v-b-tooltip.hover="'Hinzufügen'" @click="openModal('createOpenSundaysModal', store)">
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EditOpenSundaysModal from './components/EditOpenSundaysModal.vue'
import CreateOpenSundaysModal from './components/CreateOpenSundaysModal.vue'
import EditHolidaysModal from './components/EditHolidaysModal.vue'
import CreateHolidaysModal from './components/CreateHolidaysModal.vue'
export default {
    name: "HolidayIndex",
    title: "Ferien",
    components: {CreateHolidaysModal, EditHolidaysModal, CreateOpenSundaysModal, EditOpenSundaysModal},

    data() {
        return {
          holidays: [],
          selectedHolidays: null,
          stores: [],
        }
    },
    methods: {
      getHolidays()
      {
        this.axios
          .get('/holidays')
          .then((response) => {
            this.holidays = response.data.data;
          })
          .catch(() => {
            this.$swal({
              icon: 'error',
              title: "Oops...",
              text: "Da ist wohl etwas schief gelaufen"
            });
          });
      },
      openModal(name, element = null) {
        this.selectedHolidays = element;
        this.$bvModal.show(name);
      },
      closeModal(name) {
        this.$bvModal.hide(name);
      },
      deleteHolidays(id)
      {
        this.$swal({
                  title: "Möchtest du die Ferien wirklich löschen?",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: `Löschen`,
                  denyButtonText: `Abbrechen`,
              })
              .then((result) => {
                  /* Read more about isConfirmed, isDenied below */
                  if (result.isConfirmed) {
                      //Send request to the Server
                      this.axios
                          .delete("/holidays/" + id)
                          .then(() => {
                              let index = this.holidays.findIndex(element => element.id == id);
                              this.holidays.splice(index, 1);

                              this.$swal({
                                  icon: "success",
                                  title: "Feriern wurden gelöscht!",
                                  toast: true,
                                  position: 'top-end',
                                  showConfirmButton: false,
                                  timer: 2000,
                                  timerProgressBar: true,
                              });
                          })
                          .catch(() => {
                              this.$swal({
                                  icon: 'error',
                                  title: 'Oops...',
                                  text: 'Da ist wohl etwas schief gelaufen.'
                              });
                          })
                  }
              });
      },
      getStores()
      {
        this.axios
          .get("/open-sundays")
          .then((response) => {
            this.stores = response.data.data;
          })
          .catch(() => {
            this.$swal({
              icon: 'error',
              title: 'Oops...',
              text: 'Da ist wohl etwas schief gelaufen'
            })
          })
      },


      deleteOpenSunday(id)
      {
        this.$swal({
                  title: "Möchtest du Offenen Sonntag wirklich löschen?",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: `Löschen`,
                  denyButtonText: `Abbrechen`,
              })
              .then((result) => {
                  /* Read more about isConfirmed, isDenied below */
                  if (result.isConfirmed) {
                      //Send request to the Server
                      this.axios
                          .delete("/open-sundays/" + id)
                          .then(() => {
                            this.stores.forEach((element, shopIndex) => {
                              let index = element.open_sundays.findIndex(item => item.id == id);
                              if(index != -1)
                              {
                                console.log(index);
                                this.stores[shopIndex].open_sundays.splice(index, 1);
                                return;
                              }
                            });

                            this.$swal({
                                icon: "success",
                                title: "Offener Sonntag wurden gelöscht!",
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 2000,
                                timerProgressBar: true,
                            });
                          })
                          .catch(() => {
                              this.$swal({
                                  icon: 'error',
                                  title: 'Oops...',
                                  text: 'Da ist wohl etwas schief gelaufen.'
                              });
                          })
                  }
              });
      },
    },
    mounted() {
      this.getHolidays();
      this.getStores();
    }
}
</script>

<style>

</style>