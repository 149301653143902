<template>
    <b-modal
        id="createOpenSundaysModal"
        size="xl"
        title="Offene Sonntage erstellen"
        @ok.prevent="handleOk"
        @shown.prevent="form.reset() || form.errors.clear() || form.fill({store_id: $parent.selectedHolidays.id})"
    >
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label for="store">Filiale*</label>
                    <select class="form-control form-control-sm" v-model="form.store_id" disabled>
                        <option :value="null">-- Wähle Filiale --</option>
                        <option :value="store.id"  v-for="store in $parent.stores" :key="store.id">{{store.name}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label for="type">Wann* </label>
                    <input type="date" class="form-control form-control-sm" v-model="form.date" :class="{'is-invalid': form.errors.has('date')}">
                    <has-error :form="form" field="date"></has-error>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label for="type">Von </label>
                    <input type="time" class="form-control form-control-sm" v-model="form.start_time" :class="{'is-invalid': form.errors.has('start_time')}">
                    <has-error :form="form" field="start_time"></has-error>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label for="type">Bis </label>
                    <input type="time" class="form-control form-control-sm" v-model="form.stop_time" :class="{'is-invalid': form.errors.has('stop_time')}">
                    <has-error :form="form" field="stop_time"></has-error>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
export default {
    data()
    {
        return {
            form: new window.Form({
                date: null,
                store_id: null,
                start_time: null,
                stop_time: null,
            })
        }
    },
    methods: {
        handleOk(evt)
        {
            evt.preventDefault();
            this.form
                .post('/open-sundays')
                .then((response) => {
                    let storeIndex = this.$parent.stores.findIndex(element => element.id == this.form.store_id);
                    this.$parent.stores[storeIndex].open_sundays.push(response.data.data);
                    this.$parent.closeModal("createOpenSundaysModal");
                    this.$swal({
                        icon: "success",
                        title: "Offener Sonntag erstellt!",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                })
                .catch(() => {

                });
        },
    },
    mounted() {

    }
}
</script>

<style>

</style>