<template>
    <b-modal
        id="editHolidaysModal"
        size="xl"
        title="Sperrtage bearbeiten"
        @ok.prevent="handleOk"
        @shown.prevent="form.fill($parent.selectedHolidays)"
    >
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label for="type">Von: </label>
                    <input type="date" class="form-control form-control-sm" v-model="form.start_date" :class="{'is-invalid': form.errors.has('start_date')}">
                    <has-error :form="form" field="start_date"></has-error>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label for="type">Bis: </label>
                    <input type="date" class="form-control form-control-sm" v-model="form.end_date" :class="{'is-invalid': form.errors.has('end_date')}">
                    <has-error :form="form" field="end_date"></has-error>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label for="color">Farbe: </label>
                    <select name="color" id="color" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('color')}" v-model="form.color">
                        <option value="#c3e6cb">Hellgrün</option>
                        <option value="#a7c4ae">Dunkelgrün</option>
                        <option value="#5a9e92">Grünblau</option>
                    </select>
                    <has-error :form="form" field="color"></has-error>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label for="type">Beschreibung: </label>
                    <input type="text" class="form-control form-control-sm" v-model="form.type" :class="{'is-invalid': form.errors.has('type')}">
                    <has-error :form="form" field="type"></has-error>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
export default {
    data()
    {
        return {
            form: new window.Form({
                id: null,
                start_date: null,
                end_date: null,
                type: null,
                color: '',
            })
        }
    },
    methods: {
        handleOk(evt)
        {
            evt.preventDefault();
            this.form.put("/holidays/" + this.form.id)
                .then((response) => {

                    let index = this.$parent.holidays.findIndex(element => element.id == this.form.id);
                    this.$parent.holidays.splice(index, 1, response.data.data);

                    
                    this.$parent.closeModal("editHolidaysModal");


                    this.$swal({
                        icon: "success",
                        title: "Ferien wurden geändert!",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                })
                .catch(() => {

                });
        },
    },
    mounted() {

    }
}
</script>

<style>

</style>